export const translation = () => {
  return {
    'en': {
      'en': 'EN',
      'zh-tw': '英文',
    },
    'zh-tw': {
      'en': 'ZH-TW',
      'zh-tw': '中文',
    },
    'resume': {
      'en': 'Resume',
      'zh-tw': '履歷',
    },
    'portfolio': {
      'en': 'Portfolio',
      'zh-tw': '作品集',
    },
    'profile': {
      'en': 'Profile',
      'zh-tw': '簡介',
    },
    'name': {
      'en': 'Kurt Liao',
      'zh-tw': '廖晨竹',
    },
    'brief': {
      'en': 'I\'m a Frontend engineer. I have over 4 years of work experience in web development. I have extensive experience building products from 0 to 1 and maintaining and improving numerous existing projects. I primarily work with Vue 3 in my current job but also have experience with other frameworks, such as React and AngularJS. Outside of work, I am interested in learning open source like Vue and Qwik and creating tools or websites as my side projects.',
      'zh-tw': '我是前端工程師，擁有超過四年的網頁開發工作經驗。對於從零到一打造產品有豐富的實戰經驗，同時也曾維護並優化多個現有專案。目前的工作主要使用 Vue 3，但也具備使用其他框架（如 React 和 AngularJS）的經驗。工作之外，我對學習開源項目（如 Vue 和 Qwik）很有興趣，也熱衷於開發工具或網站作為我的 side projects。',
    },
    'export': {
      'en': 'Download PDF',
      'zh-tw': '下載 PDF',
    },
    'experience': {
      'en': '💼 Employment History',
      'zh-tw': '💼 工作經歷',
    },
    'description': {
      'en': 'Description',
      'zh-tw': '說明',
    },
    'work-duties': {
      'en': 'Work duties',
      'zh-tw': '工作內容',
    },
    'swifteam': {
      'en': 'Swif.ai(YC S20)',
      'zh-tw': 'Swif.ai(YC S20)',
    },
    'swifteam-period-content': {
      'en': 'Jan 2023 ~ Present',
      'zh-tw': '2023/01 ~ 現職',
    },
    'swifteam-position-content': {
      'en': 'Frontend Engineer(Fully Remote)',
      'zh-tw': '前端工程師(遠端)',
    },
    'stern': {
      'en': 'Stern Information',
      'zh-tw': '思騰資訊',
    },
    'stern-period-content': {
      'en': 'Apr 2022 ~ Dec 2022',
      'zh-tw': '2022/04 ~ 2022/12',
    },
    'stern-position-content': {
      'en': 'Frontend Engineer',
      'zh-tw': '前端工程師',
    },
    'stern-work-duties-content': {
      'en': `<li class="list-item list-inside content-text">
      <span>Worked with design to create an educational web product for schools in Taiwan.</span>
    </li>
    <li class="list-item list-inside content-text">
      <span>Migrate the existing Vue2 code‐based project to Vue3.</span>
    </li>
    <li class="list-item list-inside content-text">
      <span>Integrate 3rd party payment service.</span>
    </li>
    <li class="list-item list-inside content-text">
      <span>Edu‐app development with Flutter(Android, iOS).</span>
    </li>`,
      'zh-tw': `<li class="list-item list-inside content-text">
      <span>與設計團隊合作，為台灣的國中小開發學校管理平台網站。</span>
    </li>
    <li class="list-item list-inside content-text">
      <span>將現有基於 Vue2 的專案遷移至 Vue3。</span>
    </li>
    <li class="list-item list-inside content-text">
      <span>整合第三方支付服務。</span>
    </li>
    <li class="list-item list-inside content-text">
      <span>使用 Flutter 開發教育應用程式（支援 Android 和 iOS）。</span>
    </li>`,
    },
    'swifteam-work-duties-content': {
      'en': `<li class="list-item list-inside content-text">
      <span>Work with design and backend teams to create the MDM‐related dashboard website for organizations.</span>
    </li>
    <li class="list-item list-inside content-text">
      <span>Integrate 3rd party services, e.g., Stripe, Segment, Intercom.</span>
    </li>
    <li class="list-item list-inside content-text">
      <span>Develop the Chrome extension for Shadow IT.</span>
    </li>
    <li class="list-item list-inside content-text">
      <span>Develop the Desktop app for self‐service software installation using Flutter.</span>
    </li>`,
      'zh-tw': `<li class="list-item list-inside content-text">
      <span>與設計和後端團隊合作，打造與 MDM 相關的平台提供許多公司使用。</span>
    </li>
    <li class="list-item list-inside content-text">
      <span>整合第三方服務，例如 Stripe、Segment、Intercom。</span>
    </li>
    <li class="list-item list-inside content-text">
      <span>開發用於 Shadow IT 的 Chrome 擴展程式。</span>
    </li>
    <li class="list-item list-inside content-text">
      <span>使用 Flutter 開發用於自助軟體安裝的桌面應用程式。</span>
    </li>`,
    },
    'ilinke': {
      'en': 'Ilinke',
      'zh-tw': '聯成數網',
    },
    'ilinke-period-content': {
      'en': 'Apr 2020 ~ Jan 2022',
      'zh-tw': '2020/04 ~ 2022/01',
    },
    'ilinke-position-content': {
      'en': 'Full Stack Engineer',
      'zh-tw': '全端工程師',
    },
    'ilinke-work-duties-content': {
      'en': `<li class="list-item list-inside content-text">
      <span>Develop and maintain four e‐commerce websites.</span>
    </li>
    <li class="list-item list-inside content-text">
      <span>Optimize SEO and speed of the website.</span>
    </li>
    <li class="list-item list-inside content-text">
      <span>Integrate 3rd party services, e.g., payment, logistics, Google GA, Google Login.</span>
    </li>
    <li class="list-item list-inside content-text">
      <span>Optimize SEO and speed of the website.</span>
    </li>
    <li class="list-item list-inside content-text">
      <span>Mobile app development with React Native (Android, iOS).</span>
    </li>
    <li class="list-item list-inside content-text">
      <span>Deploy to AWS with EC2, S3, Cloudfront, Route53.</span>
    </li>`,
      'zh-tw': `<li class="list-item list-inside content-text">
      <span>開發並維護四個不同的電商網站。</span>
    </li>
    <li class="list-item list-inside content-text">
      <span>整合第三方服務，例如支付、物流、Google GA、Google 登入。</span>
    </li>
    <li class="list-item list-inside content-text">
      <span>優化網站的 SEO 和加載速度。</span>
    </li>
    <li class="list-item list-inside content-text">
      <span>使用 React Native 開發手機應用程式（支援 Android 和 iOS）。</span>
    </li>
    <li class="list-item list-inside content-text">
      <span>部署專案至 AWS，使用到的服務有 EC2、S3、Cloudfront、Route53</span>
    </li>`,
    },
    'jcolor': {
      'en': 'JColor, an e-commerce website of TPEx-listed printing company.',
      'zh-tw': '捷可印, 上櫃印刷公司電商網站',
    },
    'cupoy': {
      'en': 'Cupoy, an AI community website.',
      'zh-tw': 'Cupoy, AI 學習社群網站',
    },
    'everprinter': {
      'en': 'Everprinter, an e-commerce website of printing company.',
      'zh-tw': '貼紙先生, 貼紙印刷電商網站',
    },
    '9199': {
      'en': '9199, an e-commerce website of online game gift card.',
      'zh-tw': '9199, 點數卡買賣電商網站',
    },
    'tech-stack': {
      'en': 'Tech Stacks',
      'zh-tw': '使用的技術',
    },
    'skills': {
      'en': '🛠️Skills',
      'zh-tw': '🛠️技能',
    },
    'education': {
      'en': '👨‍🎓Education',
      'zh-tw': '👨‍🎓學歷',
    },
    'college': {
      'en': 'National Taiwan University of Science and Technology (BS)',
      'zh-tw': '臺灣科技大學(學士)',
    },
    'period': {
      'en': 'Period',
      'zh-tw': '時期',
    },
    'college-period-content': {
      'en': 'Sep 2015 ~ Jun 2019',
      'zh-tw': '2015/09 ~ 2019/06',
    },
    'major': {
      'en': 'Major',
      'zh-tw': '科系',
    },
    'major-content': {
      'en': 'Computer Science & Information Engineering',
      'zh-tw': '資訊工程系',
    },
    'job-seeking': {
      'en': '👀Looking for a chance...',
      'zh-tw': '👀尋找機會...',
    },
    'position': {
      'en': 'Position',
      'zh-tw': '職稱',
    },
    'position-content': {
      'en': 'Senior Frontend Engineer',
      'zh-tw': '資深前端工程師',
    },
    'place': {
      'en': 'Place',
      'zh-tw': '地點',
    },
    'place-content': {
      'en': 'Taichung Taiwan, Remote',
      'zh-tw': '台中、遠端',
    },
    'rabbit-fly': {
      'en': 'Rabbit fly. Down stairs game made in Unity:',
      'zh-tw': 'Rabbit fly, 一款使用 Unity 製作的下樓梯遊戲：',
    },
    'video': {
      'en': 'Video',
      'zh-tw': '影片',
    },
    'link': {
      'en': 'Link',
      'zh-tw': '連結',
    },
    'project': {
      'en': 'Projects',
      'zh-tw': '專案',
    },
  }
}
